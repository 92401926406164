.video-list {
    width:645px;
}

div.react-player__preview, .video-list video {
    border: 1px solid #3f59e4;
    border-radius: 10px;
}

div.react-player__play-icon {
    background: none !important;
    border-radius: 0 !important;
    border: none !important;
    background-image: url(../../public/resources/play.svg) !important;
    width: 130px !important;
    height: 130px !important;
    background-size: 130px 130px !important;
    margin-left: -70px !important;
}