

h2.list-title {
    font-family: aeonik, sans-serif !important;
    margin-bottom: 0;
}
.ui.cards>.card.list-card {
    margin-top: 3%;
    background: none;
    box-shadow:none;
    min-width: 25em;
}
.list-card .list {
    margin-top: 0;
}

.segment.list-card {
    padding-top: 0.2em;
    padding-bottom: 0.2em;
}

.list-title {
    margin-left: 1.2em;
    margin-top:0.5em !important;
    color: #3f59e4;
}
.list-card div.radius-hover {
    border-radius: 0.4em;
    padding: 0.5em;
}

.ui.list>div.item {
    padding: 0;
}
.ui.list>div.item .header {
    font-family: aeonik, sans-serif !important;
}

.list-card .radius-hover:hover {
    background: #3f59e4 !important;
    cursor: pointer;
}
.list-card .radius-hover:hover .content .header,
.list-card .radius-hover:hover .content .description {
    color: #fff !important;
}

.list-card div.item {
    margin-top: 0.5em;
    font-size: 1.3rem;
    padding: 0 !important;
    margin: 0 !important;
}
.list-card img.ui.image {
    display: inline-block;
    vertical-align:initial !important;
    filter: sepia(100%) hue-rotate(192deg) drop-shadow(2px 1px 1px rgb(0 0 0 / 0.4));
}
.list-card div.content {
    display: inline-block;
    margin-left: 1em;
}