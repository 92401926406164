.footer-container {
  height:70px;
}
.footer-left, .footer-right {
  position:fixed;
  bottom:1em;
}
.footer-left {
  left:0;
  z-index:-1000;
}
.footer-right {
  right:0;
}

.userControls{
  float: right;
  margin-right: 1em;
}

.userControls img {
  margin-left: 1em;
  margin-bottom: 0.5em;
}

.userControl:hover {
  cursor:pointer;
}

.userControls>span:hover{
  cursor:pointer;
  text-decoration: underline;
}

.userControls {
  font-size: 1.1rem;
}