@font-face {
  font-family: aeonik;
  src: url('https://cdn.demo.okta.com/fonts/aeonik-regular.woff2') format('woff2');
}

html, div.ui.inverted.dimmer{
  height:100%;
  background-image: linear-gradient(transparent, #F4F2EC 95%), url('https://cdn.demo.okta.com/images/demo-okta-bg-blue.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #F4F2EC;
  background-attachment: fixed;
}
div.ui.inverted.dimmer {
  height:100%;
}

body {
  color: #343433;
  font-family: aeonik, sans-serif !important;
  font-size: 1.4rem;
  min-height: 100%;
  padding-bottom: 20px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: transparent !important;
}

body a {
  text-decoration: underline;
}

.ui div.row.title-row {
  margin:2rem 0 0 0;
  padding:0;
}

.ui.card.info-card {
  margin-top: 5%;
}
.ui.card.list-card, .ui.card.list-container, .ui.card.info-card {
  min-width: 20em;
  font-size:1em;
  padding: 0.5em;
  border: none;
  background: none;
  box-shadow: none;
}
.ui.cards>.card.info-card img {
  background: none;
}
.ui.grid>.row>.column.info-card{
  margin: 10rem 0 0 2rem;
}

div img.ui.brandLogo{
  max-width: 20em !important;
  display: inline;
}
div img.ui.starterPack {
  max-width: 15em !important;
  border-radius: 10px;
}

.ui.card.list-container {
  max-width: unset;
  width: unset;
}
.ui.card.list-card {
  width: 36rem;
}
